import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/pages/home', pathMatch: 'full' },
  { path: 'pages', loadChildren: 'src/app/pages/pages.module#PagesModule' },
  { path: 'page', loadChildren: 'src/app/labgrade/labgradechemical.module#LabgradechemicalModule' },
  { path: '**', redirectTo: '/pages/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
